
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  onMounted,
} from "vue";
import { api } from "@/utils/api-config";
import { gpf } from "@/utils/global-functions";
import { dataProfile } from "./index";
// import { setCookie } from "@/utils/cookie-next";
import { prettierPhotoUser } from "@/utils/user";

const ModalUpdateProfile = defineAsyncComponent(
  () => import(`./components/modal-update-profile.vue`)
);

const ModalLupaPassword = defineAsyncComponent(
  () => import("@/views/login/components/modal-lupa-password.vue")
);

const ModalUbahPassword = defineAsyncComponent(
  () => import("./components/modal-ubah-password.vue")
);

const ModalUbahPin = defineAsyncComponent(
  () => import("./components/modal-ubah-pin.vue")
);

export default defineComponent({
  components: {
    ModalUpdateProfile,
    ModalLupaPassword,
    ModalUbahPassword,
    ModalUbahPin,
  },
  setup() {
    const getDataProfile = async () => {
      const url = api.publicProfile;
      const hitApi = await api.hitApiGet(url);
      const rs = hitApi.data.response;
      const ex = dataProfile;
      ex.username = rs.username;
      ex.email = rs.email;
      ex.name = rs.name;
      ex.phone = rs.phone;
      ex.photo = rs.photo;

      // setCookie("login-data", ex);
    };

    onMounted(() => {
      getDataProfile();
    });

    const showModalUP = () => {
      gpf.showModal("#modal-update-profile");
    };

    const modalLupaPassword = () => {
      gpf.showModal("#modal-lupa-password");
    };

    const modalUbahPassword = () => {
      gpf.showModal("#modal-ubah-password");
    };

    const modalUbahPin = () => {
      gpf.showModal("#modal-ubah-pin");
    };

    // update link photo user
    const photoUser = computed(() => prettierPhotoUser(dataProfile.photo));

    return {
      gpf,
      dataProfile,
      modalLupaPassword,
      modalUbahPassword,
      modalUbahPin,
      getDataProfile,

      showModalUP,
      photoUser,
    };
  },
});
