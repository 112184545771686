import { getLoginData } from "@/utils/cookie-next";
import { reactive } from "vue";

const ld = getLoginData();
export const dataProfile = reactive({
  id: ld?.id || "",
  username: ld?.username || "",
  email: ld?.email || "",
  name: ld?.name || "",
  phone: ld?.phone || "",
  photo: ld?.photo || "",
  role: ld?.role || "",
  token: ld?.token || "",
});
